import flatry from '@admin/utils/flatry'

const PaginationMixin = {
  data () {
    return {
      loading: true,
      lists: [],
      limit: 20,
      page: 1,
      count: 0,
      where: {}
      // 数据导出接收参数
      // ExportListsFather: [],
      // ExportFileName: ''

    }
  },
  async created () {
    if (!this.noLoading) {
      await this.getList()
    }
  },
  methods: {
    async changeSize (val) {
      this.limit = val
      await this.getList()
    },
    async pageChange (val) {
      this.loading = true
      this.page = val
      await this.getList()
    },
    // 获取数据
    async getList (where, mv = true) {
      this.loading = true

      let param = Object.assign({
        limit: this.limit,
        page: this.page
      }, this.where)

      const { data } = await flatry(this.function(param))
      if (data) {
        this.lists = data.data
        this.count = data.count
      }

      this.loading = false

      // this.ExportListsFather = this.lists
      // this.ExportFileName = new Date().toLocaleString()
    },
    //
    handleModelEdit (model) {
      let url = this.$route.path + '/update'
      this.$router.push({ path: url, query: { id: model.id } })
    },
    handleModelDelete (model, msg) {
      this.$deleteDialog(msg || ('ID 为' + model.id + '的数据'), async action => {
        if (action === 'confirm') {
          // this.loading = true

          const { data } = await flatry(this.delete({ id: model.id, _p_time: new Date() }))

          if (data) {
            this.lists.forEach((item, index) => {
              if (item.id === model.id) {
                this.lists.splice(index, 1)
              }
            })

            this.$message({
              type: 'success',
              message: data.msg
            })
            this.loading = false
          }
          this.loading = false
        }
      })
    }
  }
}

export default PaginationMixin
