<template>
    <div class="box">
        <div class="box-toolbar">
            <div class="box-item">
                <el-select
                    v-model="program_type"
                    placeholder="请选择"
                    @change="changeModel"
                >
                    <el-option
                        v-for="(item,index) in program_types"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="box-item">
                <el-button
                    icon="el-icon-search"
                    circle
                    @click="searchGo()"
                ></el-button>
            </div>
        </div>
        <el-table
            v-loading="loading"
            border
            :stripe="true"
            :data="lists"
            height="75vh"
        >
            <el-table-column
                prop="filename"
                label="文件名"
            >
            </el-table-column>

            <el-table-column label="操作" fixed="right" width="200px">
                <template slot-scope="scope">
                    <el-button
                        type="primary"
                        @click="openView(scope.row.filename)"
                        plain
                        size="mini"
                    >查看</el-button>
                     <el-button
                        type="primary"
                        @click="downFile(scope.row.filename)"
                        plain
                        size="mini"
                    >下载</el-button>
                </template>
            </el-table-column>
        </el-table>
        <page
            :pageSize="limit"
            :count="count"
            @change="pageChange"
            @changeSize="changeSize"
        >
        </page>
        <logModel ref="logModel" />
    </div>
</template>

<script>

import LogService from '@admin/services/LogService'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'
import logModel from './_Model'
import flatry from '@admin/utils/flatry'

export default {
  name: 'LogModel',
  components: { Page, logModel },
  data () {
    return {
      loading: true,
      function: function (param) {
        return LogService.httpLogAll(param)
      },
      program_type: 2,
      program_types: [
        { name: '系统日志', id: 1 },
        { name: '开关日志', id: 2 }
      ]
    }
  },
  mixins: [pagination],
  methods: {
    async searchGo () {
      this.page = 1
      let param = {
        type: this.program_type
      }
      this.where = param
      await this.getList(param)
    },
    async openView (item) {
      const { data } = await flatry(LogService.view({ type: this.program_type, filename: item }))
      if (data) {
        console.log(data)
        this.$refs.logModel.openView(data.data)
      }
    },
    async downFile (item) {
      const { data } = await flatry(LogService.down({ type: this.program_type, filename: item }))
      if (data) {
        console.log(data)
      }
    },
    async changeModel () {
      await this.searchGo()
    }
  }
}
</script>
<style lang='scss' scoped>
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>
