<template>
<el-dialog
    :visible.sync="viewBox"
    width="70%"
  >
  <p v-for="(item,index) in jsonData" :key="index">{{item}}</p>
  <!-- <json-viewer
  :value="jsonData"
  :expand-depth=5
  copyable
  boxed
  sort>
  </json-viewer> -->
  </el-dialog>
</template>

<script>
import JsonViewer from 'vue-json-viewer'

export default {
  name: 'ModelTanc',
  components: { JsonViewer },
  data () {
    return {
      viewBox: false,
      jsonData: null
    }
  },
  methods: {
    openView (item) {
      this.jsonData = null
      this.viewBox = true
      this.jsonData = item
    }
  }
}

</script>
<style lang='' scoped>

</style>
