import Vue from 'vue'

const AdminService = {
  all (param) {
    return Vue.http.get('log/index', param)
  },
  view (id) {
    return Vue.http.get('log/view', id)
  },
  down (param) {
    return Vue.http.download('POST', 'log/down', [], param)
  },
  httpLogAll (param) {
    return Vue.http.get('log/http-index', param)
  }
}

export default AdminService
