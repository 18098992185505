<template>
    <el-pagination
     class="page"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      @prev-click="prev"
      @next-click="next"
      :page-sizes="[20, 50, 100, 200]"
      :page-size="2"
      :pager-count="7"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count">
    </el-pagination>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    // 页面显示数
    pageSize: {},
    // 总记录数
    count: {}

  },
  methods: {
    prev (val) {
      this.go(val)
    },
    next (val) {
      this.go(val)
    },
    handleSizeChange (val) {
      this.$emit('changeSize', val)
    },
    handleCurrentChange (val) {
      this.go(val)
    },
    go (page) {
      this.$emit('change', page)
    }
  }
}
</script>

<style scoped>
.page{
    margin-top: 20px;
    text-align: right;
}
</style>
